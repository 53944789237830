.grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 10px;
}

.grid-item {
    padding: 20px;
    font-size: 16px;
    text-align: left;
}

.twoColumns {
    column-count: 2;
    column-gap: 50px;
    page-break-inside: avoid;
    break-inside: avoid;
    break-before: always;
}
