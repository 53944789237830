.carousel-caption {
    left: auto;
    right: auto;
    text-align: center;
    margin: auto;
    width: 100%;
    background-color: white;
    bottom: -30px;
    z-index: 1000;
    color: black;
}
