#page-wrap {
    text-align: center;

    /* Prevent sidebar from showing a scrollbar on page */
    overflow: auto;
}

/* Individual item */
.bm-item {
    display: inline-block;

    /* Our sidebar item styling */
    text-decoration: none;
    margin-bottom: 10px;
    color: #1b396b;
    transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
    /*color: white;*/
    background-color: #e8f0fe;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 32px;
    height: 26px;
    left: 36px;
    top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #1b396b;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #1b396b;
}

/* General sidebar styles */
.bm-menu {
    background: white;
    padding: 2.5em 0.5em 0;
    font-size: 1.1em;
    border-right: 1px solid #000;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #1b396b;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

.sidebar {
    background: pink;
    /* padding-left: 10px; */
    position: sticky !important;
    top: 80px;
    z-index: 20;
    /* margin: 0px; */
    /* padding: 0px; */
    padding-top: 80px;
    /* border-right: 1px solid #ececec; */
}

.overflowWrapper {
    overflow: hidden;
    display: block !important;
    height: 100% !important;
}
